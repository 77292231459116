import { useEffect, useState } from "react";

export default function StatusCard({
  status,
  lastRun,
  service,
  otherInfo,
  errorInfo,
}) {
  const [isBlinking, setIsBlinking] = useState(true);
  const [isOtherInfoVisible, setIsOtherInfoVisible] = useState(false);
  const [isErrorInfoVisible, setIsErrorInfoVisible] = useState(false);

  const toggleOtherInfoVisibility = () => {
    setIsOtherInfoVisible((prev) => !prev);
  };

  const toggleErrorInfoVisibility = () => {
    setIsErrorInfoVisible((prev) => !prev);
  };

  // Toggle the blink state at a very fast interval
  useEffect(() => {
    if (status === "up") {
      const interval = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, Math.random() * (1100 - 800) + 100); // Random interval between 100ms and 500ms
      return () => clearInterval(interval);
    }
  }, [status]);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "8px",
        paddingTop: "16px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        paddingRight: "34px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        marginBottom: "16px",
      }}
    >
      {/* Top Row: Service Name, Status, and Last Run */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "8px",
          cursor: "pointer",
        }}
        onClick={() => {
          toggleErrorInfoVisibility();
        }}
      >
        {/* Left: Service Name */}
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            color: "#333",
            cursor: "pointer",
          }}
          onClick={() => {
            toggleOtherInfoVisibility();
          }}
        >
          {service}
        </div>

        {/* Center: Last Run */}
        <div style={{ fontSize: "0.875rem", color: "#666" }}>
          {lastRun && <span>Last Triggered: {lastRun}</span>}
        </div>

        {/* Right: Blinking Indicator */}
        <div>
          <div
            style={{
              height: "12px",
              width: "12px",
              borderRadius: "50%",
              backgroundColor: status === "up" ? "green" : "red",
              opacity: isBlinking ? 1 : 0, // Toggle visibility
            }}
          />
        </div>
      </div>

      {/* Other Info Section */}
      {isOtherInfoVisible && otherInfo && (
        <div
          style={{
            fontSize: "0.875rem",
            color: "#555",
            marginTop: "8px",
            lineHeight: "1.5",
          }}
        >
          {Object.entries(otherInfo).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
          ))}
        </div>
      )}
      {isErrorInfoVisible && errorInfo && (
        <div
          style={{
            fontSize: "0.875rem",
            color: "#555",
            marginTop: "8px",
            lineHeight: "1.5",
          }}
        >
          {errorInfo}
        </div>
      )}
    </div>
  );
}
