import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import TicketAPIs from "../../APIs/ticket";
import SampleDataTable from "Components/DataTables";
import SearchForm from "Components/SearchForm";
import { toast } from "react-toastify";
import { wrapLastTextInBracketsWithStrong } from "Utils/utils";

const OfficeClear = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [ticketStationCodeSelections, setTicketStationCodeSelections] =
    useState({});
  const [user, setUser] = useState();
  const [refresh, setRefresh] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [officeClearLoader, setOfficeClearLoader] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          className={`badge ${row.original?.cancelDate && "custom-badge"} ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticketNo}
          {row.original?.cancelDate && <span className="cancel-btn"></span>}
        </a>
      ),
    },
    {
      accessor: "location",
      Header: "Location",
      Cell: ({ row }) => {
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {row.original.digAddress} {"\n"}
            {row.original.digCity}
            {row.original.company_name} {"\n"}
            {row.original.contractor_name} {"\n"}
            {row.original.region} {"\n"}
            <strong>{`(${row.original.priority})`}</strong>
          </div>
        );
      },
    },
    { accessor: "entered", Header: "Entered" },

    {
      accessor: "due",
      Header: "Due",
      Cell: ({ row }) => (
        <p>
          {row.original.rescheduleDate
            ? row.original.rescheduleDate
            : row.original.due}
        </p>
      ),
    },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <>
          {Array.isArray(row.original.code)
            ? row.original.code.map((code, index) => (
                <div key={index} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`station-code-${code.id}`}
                    checked={
                      ticketStationCodeSelections[row.original.id]?.includes(
                        code.id
                      ) || false
                    }
                    onChange={() =>
                      handleStationCodeChange(row.original.id, code.id)
                    }
                    disabled={!code.isOfficeClear} // Disable checkbox if isOfficeClear is false
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`station-code-${code.id}`}
                  >
                    {code.name}
                  </label>
                </div>
              ))
            : null}
        </>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
  ];

  const handleSelectionChange = (selectedIds) => {
    setSelectedIds(selectedIds);
  };

  const handleStationCodeChange = (ticketId, stationCodeId) => {
    const selections = { ...ticketStationCodeSelections };
    const ticketSelections = selections[ticketId] || [];

    if (ticketSelections.includes(stationCodeId)) {
      selections[ticketId] = ticketSelections.filter(
        (id) => id !== stationCodeId
      );
    } else {
      selections[ticketId] = [...ticketSelections, stationCodeId];
    }

    setTicketStationCodeSelections(selections);
  };
  const handleOfficeClear = async () => {
    if (selectedIds.length === 0) {
      toast.warning("Select at least one ticket.");
      return;
    }

    const allTicketsValid = selectedIds.every(
      (ticketId) =>
        ticketStationCodeSelections[ticketId] &&
        ticketStationCodeSelections[ticketId].length > 0
    );

    if (!allTicketsValid) {
      toast.warning(
        "Please select at least one station code for each selected ticket."
      );
      return;
    }

    setOfficeClearLoader(true);
    let officeClearSuccess = false;

    for (const ticketId of selectedIds) {
      try {
        // Get the ticket data
        const ticketData = searchedData.find((data) => data.id === ticketId);

        // Get all eligible station codes (where isOfficeClear is true)
        const eligibleStationCodes =
          ticketData?.code?.filter((code) => code.isOfficeClear) || [];

        // Get selected station codes for this ticket
        const selectedStationCodeIds =
          ticketStationCodeSelections[ticketId] || [];

        // Get unselected station codes (all eligible codes that weren't selected)
        const unselectedStationCodeIds = eligibleStationCodes
          .filter((code) => !selectedStationCodeIds.includes(code.id))
          .map((code) => code.id);

        // First, send unselected station codes to locate
        if (unselectedStationCodeIds.length > 0) {
          console.log(`un selected station codes`, unselectedStationCodeIds);
          try {
            await TicketAPIs.sendToLocate({
              ticketId: [ticketId],
              stationCodes: unselectedStationCodeIds,
            });

            // if (response.data.success) {
            //   const selectedTicketsNumbers = searchedData
            //     .filter((item) => selectedIds.indexOf(item.id) > -1)
            //     .map((item) => item.ticketNo);
            //   setSendToLocateMessage(
            //     `Following Tickets ${selectedTicketsNumbers.join(
            //       ","
            //     )} has been send to locate for station code ${}`
            //   );
            // }
          } catch (error) {
            console.error("Error sending to locate:", error);
          }
        }

        // Then process selected station codes for office clear
        for (const stationCodeId of selectedStationCodeIds) {
          try {
            const complete = await TicketAPIs.completeTicket({
              ticketId: [ticketId],
              userId: user?.id,
              isOfficeClear: true,
              stationCodes: [stationCodeId],
              // here we need to check if all station codes are selected then we need to skip the complete other wise skipComplete will be false
              skipComplete: !(
                selectedStationCodeIds.length === eligibleStationCodes.length
              ),
            });

            if (complete.data.success) {
              await TicketAPIs.officeClearStation({
                ticketId,
                stationCodeId,
                isOfficeCleared: true,
                userId: user?.id,
              });
              officeClearSuccess = true;
            }
          } catch (error) {
            setSelectedIds([]);
          }
        }
      } catch (error) {
        console.error("Error processing ticket:", ticketId, error);
      }
    }

    if (officeClearSuccess) {
      const selectedTicketsNumbers = searchedData
        .filter((item) => selectedIds.indexOf(item.id) > -1)
        .map((item) => item.ticketNo);
      setSuccessMsg(
        `Following Tickets ${selectedTicketsNumbers.join(
          ","
        )} has been cleared.`
      );
      setSelectedIds([]);
      setTicketStationCodeSelections({});
      setTimeout(() => {
        setRefresh(!refresh);
      }, 1000);
    }
    setOfficeClearLoader(false);
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  return (
    <>
      <section>
        <Heading text={"Office Clear"} />
        {successMsg && (
          <div className="successMsg">
            <strong style={{ margin: 0, color: "rgb(0,120,0)" }}>
              {successMsg}
            </strong>
            <MdClose
              size={20}
              color={"gray"}
              onClick={() => setSuccessMsg("")}
              style={{ position: "absolute", top: 10, right: 10 }}
            />
          </div>
        )}
        {/* {sendToLocateMessage && (
          <div className="alert alert-primary" role="alert">
            <strong style={{ margin: 0 }}>{sendToLocateMessage}</strong>
            <MdClose
              size={20}
              color={"gray"}
              onClick={() => setSendToLocateMessage("")}
              style={{ position: "absolute", top: 10, right: 10 }}
            />
          </div>
        )} */}
        <Card>
          <Row>
            <SearchForm
              setSearchedData={setSearchedData}
              setIsLoading={setIsLoading}
              filters={{ isOfficeClear: true, completedDate: false }}
              loadDataOnMount={true}
              refresh={refresh}
              from="officeClear"
              hiddenElements={["cancelDate"]}
            />
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Button
                className="me-3"
                disabled={officeClearLoader}
                onClick={handleOfficeClear}
              >
                <AiOutlineCheckCircle />
                {officeClearLoader ? "Loading..." : "Office Clear"}
              </Button>
              <Button
                onClick={async () => {
                  if (selectedIds?.length > 0) {
                    await TicketAPIs.sendToLocate({
                      ticketId: selectedIds,
                    });
                    setRefresh(!refresh);
                    const selectedTicketsNumbers = searchedData
                      .filter((item) => selectedIds.indexOf(item.id) > -1)
                      .map((item) => item.ticketNo);
                    setSuccessMsg(
                      `Following Tickets ${selectedTicketsNumbers.join(
                        ","
                      )} has been send to locate.`
                    );
                  } else {
                    toast.warning("Select atleast one ticket");
                  }
                }}
              >
                <AiOutlineCheckCircle />
                Send to Locate
              </Button>
            </Col>
          </Row>
          <SampleDataTable
            columns={columns}
            data={searchedData}
            onSelectionChange={handleSelectionChange}
          />
        </Card>
      </section>
    </>
  );
};

export default OfficeClear;
